const skyConfig = {
  httpUrl: "https://annotator.testing.realityos.io",
  httpDelegate: null,
  wsUrl: "wss://annotator.testing.realityos.io",
  wsFactory: null,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(globalThis as any).skyConfig = skyConfig;
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
if (urlParams.has("local")) {
  skyConfig.httpUrl = "http://localhost:8183";
  skyConfig.wsUrl = "ws://localhost:8183";
}
console.log(`SkyConfig: ${JSON.stringify(skyConfig)}`);

export default skyConfig;
