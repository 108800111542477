import { createContext } from "@lit/context";
import { AuthenticationState, AuthenticationUser } from "./reducer";

export const authenticationStateContext = createContext<AuthenticationState>(
  "authentication-state-context",
);

export const authenticationContextInitialState: AuthenticationState = {
  user: undefined,
  status: "unauthenticated",
  error: undefined,
};

export const authenticationMethodsContext = createContext<{
  login(): Promise<AuthenticationUser | undefined>;
  logout(): Promise<void>;
}>("authentication-methods-context");
