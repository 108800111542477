// DO NOT EDIT - This file is autogenerated by scripts/wrap-css-in-lit-css.js

import { css } from 'lit';

export const styles = css`
    :host {
  display: block;
  min-height: 100dvh;
  background-color: var(--am-sys-color-surface-1);
  color: var(--am-sys-color-on-surface);
}

`;

export default styles;
    