import client from "../client";

export type SocketListenerEventName =
  | "connect"
  | "disconnect"
  | "message"
  | "error"
  | "reconnect"
  | "reconnect_attempt";

/**
 * SocketClient is a class that manages a WebSocket connection to RealityOS.
 *
 * It provides a way set the user channel and subscribe to topics and receive notifications from RealityOS.
 *
 * @example
 * ```ts
 * const socketClient = new SocketClient({
 *   socketUrl: "ws://localhost:8080",
 *   reconnect: true,
 *   reconnectDelay: 5000,
 *   reconnectDelayMax: 20000,
 * });
 *
 * socketClient.on("connect", () => {
 *   console.log("connected to socket server");
 * });
 *
 * socketClient.on("message", (message) => {
 *   console.log("received message", message);
 * });
 *
 * socketClient.connect("user_id");
 * ```
 */
export class SocketClient {
  /**
   * Indicates if the client is connected to the WebSocket server.
   */
  public get connected() {
    return this._connected;
  }

  private _socketUrl: string;
  private _socket!: WebSocket;
  private _reconnect: boolean;
  private _reconnectDelay: number;
  private _reconnectDelayMax: number;
  private _reconnectTimeout: ReturnType<typeof setTimeout> | null = null;
  private _listeners = new Map<SocketListenerEventName, CallableFunction[]>();
  private _userId: string = "";
  private _connected = false;

  constructor({
    socketUrl,
    reconnect,
    reconnectDelay,
    reconnectDelayMax,
  }: {
    socketUrl: string;
    reconnect: boolean;
    reconnectDelay: number;
    reconnectDelayMax: number;
  }) {
    this._socketUrl = socketUrl;
    this._reconnect = reconnect;
    this._reconnectDelay = reconnectDelay;
    this._reconnectDelayMax = reconnectDelayMax;
  }

  /**
   * Connects to the WebSocket server.
   *
   * @param userId - The user used for the default channel.
   */
  public connect(userId?: string) {
    if (userId) {
      this._userId = userId;
    }

    this._socket = new WebSocket(this._socketUrl);

    this._socket.onopen = this._handleOnOpen.bind(this);
    this._socket.onerror = this._handleOnError.bind(this);
    this._socket.onmessage = this._handleOnMessage.bind(this);
    this._socket.onclose = this._handleOnclose.bind(this);
  }

  /**
   * Disconnects from the WebSocket server.
   */
  public disconnect() {
    this._socket.close();

    this._connected = false;
    this._dispatch("disconnect");
    this._removeListeners();
  }

  /**
   * Json serializes the message and sends it to the WebSocket server.
   *
   * @param message - The message to send to the WebSocket server.
   */
  public send(message: unknown) {
    this._socket.send(JSON.stringify(message));
  }

  /**
   * Adds a listener to the specified event.
   *
   * @param event - The event to listen to.
   * @param callback - The callback to call when the event is triggered.
   */
  public on(event: SocketListenerEventName, callback: (e: unknown) => void) {
    const listeners = this._listeners.get(event) || [];
    listeners.push(callback);

    this._listeners.set(event, listeners);
  }

  /**
   * Removes a listener from the specified event.
   *
   * @param event - The event to remove the listener from.
   * @param callback - The callback to remove from the event.
   */
  public off(event: SocketListenerEventName, callback: (e: unknown) => void) {
    const listeners = this._listeners.get(event);

    if (listeners) {
      const index = listeners.indexOf(callback);

      if (index !== -1) {
        listeners.splice(index, 1);

        if (listeners.length > 0) {
          this._listeners.set(event, listeners);
        } else {
          this._listeners.delete(event);
        }
      }
    }
  }

  /**
   * Subscribes to a topic.
   *
   * @param topic - The topic to subscribe to.
   */
  public async subscribeToTopic(topic: string) {
    if (!this._userId) {
      console.error(
        "no valid connection channel to subscribe to, try connecting first with a userId: client.connect('user_id')",
      );
      return;
    }

    try {
      const { data } = await client.POST("/v1/notifications/subscribe", {
        body: {
          subscriber: this._userId,
          topic,
        },
      });

      return data;
    } catch (e) {
      console.error("error subscribing to topic", e);
    }
  }

  /**
   * Unsubscribes from a topic.
   *
   * @param topic - The topic to unsubscribe from.
   */
  public async unSubscribeFromTopic(topic: string) {
    if (!this._userId) {
      console.error("no channel to subscribe to");
      return;
    }

    try {
      const { data } = await client.POST("/v1/notifications/unsubscribe", {
        body: {
          subscriber: this._userId,
          topic,
        },
      });

      return data;
    } catch (e) {
      console.error("error unsubscribing from topic", e);
    }
  }

  private _connectToUserChannel() {
    if (!this._userId) {
      console.error("no user id to connect to");
      return;
    }

    this.send({
      channel: this._userId,
    });
  }

  private _dispatch(event: SocketListenerEventName, data?: unknown) {
    const listeners = this._listeners.get(event);

    if (listeners) {
      listeners.forEach((listener) => listener(data));
    }
  }

  private _handleOnOpen(e: WebSocketEventMap["open"]) {
    if (this._reconnectTimeout) {
      clearTimeout(this._reconnectTimeout);
      this._reconnectTimeout = null;
      this._dispatch("reconnect");
    }

    console.debug("SocketClient: connected to socket", e);

    this._connected = true;
    this._connectToUserChannel();
    this._dispatch("connect");
  }

  private _handleOnError(e: WebSocketEventMap["error"]) {
    console.error("error connecting to socket", e);
    this._dispatch("error", e);
  }

  private _handleOnMessage(e: WebSocketEventMap["message"]) {
    const data = JSON.parse(e.data);
    const listeners = this._listeners.get("message");

    if (listeners) {
      listeners.forEach((listener) => listener(data));
    }

    console.debug("SocketClient: received message", data);
  }

  private _handleOnclose(e: WebSocketEventMap["close"]) {
    console.debug("SocketClient: socket closed", e);

    this._connected = false;
    this._dispatch("disconnect");

    if (this._reconnect) {
      this._reconnectConnection();
    }
  }

  private _reconnectConnection() {
    if (this._reconnect) {
      this._reconnectTimeout = setTimeout(() => {
        this._dispatch("reconnect_attempt");
        this.connect();
      }, this._reconnectDelay);

      if (this._reconnectDelay < this._reconnectDelayMax) {
        this._reconnectDelay += 5000;
      }
    }
  }

  private _removeListeners() {
    this._socket.onopen = null;
    this._socket.onerror = null;
    this._socket.onmessage = null;
    this._socket.onclose = null;

    this._listeners.clear();
  }
}
