import { ReactiveController } from "lit";
import { ContextProvider } from "@lit/context";
import {
  authenticationStateContext,
  authenticationContextInitialState,
  authenticationMethodsContext,
} from "./context";
import {
  AuthenticationAction,
  AuthenticationError,
  AuthenticationKind,
  AuthenticationStatus,
  AuthenticationUser,
  authenticationReducer,
} from "./reducer";
import { AuthenticationHost } from "./types";
import { AuthenticationService } from "../../services/authentication-service";
import { config } from "../../config";
import { UsersService } from "../../services/user-service";

const getCookieValue = (name: string) =>
  JSON.parse(
    document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
      "{}",
  );
export class AuthenticationProvider<HostElement extends AuthenticationHost>
  implements ReactiveController
{
  private _host: HostElement;
  private _stateProvider: ContextProvider<typeof authenticationStateContext>;
  // @ts-ignore - It's currently unused, but it needs to be initialized to read the provider value
  private _methodsProvider: ContextProvider<
    typeof authenticationMethodsContext
  >;
  private _authService = new AuthenticationService();
  private _userService = new UsersService();

  get status(): AuthenticationStatus {
    return (
      this._stateProvider.value?.status ??
      authenticationContextInitialState.status
    );
  }

  get user(): AuthenticationUser | undefined {
    return this._stateProvider.value?.user;
  }

  get error(): AuthenticationError | undefined {
    return (
      this._stateProvider.value?.error ??
      authenticationContextInitialState.error
    );
  }

  constructor(host: HostElement) {
    this._host = host;
    this._host.addController(this);

    this._stateProvider = new ContextProvider(host, {
      context: authenticationStateContext,
      initialValue: authenticationContextInitialState,
    });

    this._methodsProvider = new ContextProvider(host, {
      context: authenticationMethodsContext,
      initialValue: {
        login: this.login.bind(this),
        logout: this.logout.bind(this),
      },
    });
  }

  public hostConnected() {
    // no-op
  }

  private _dispatch(action: AuthenticationAction) {
    const state = this._stateProvider.value;
    const newState = authenticationReducer(state, action);
    this._stateProvider.setValue(newState);
  }

  public async login() {
    this._dispatch({
      type: AuthenticationKind.AUTHENTICATING,
      payload: { status: "authenticating" },
    });

    const response = await fetch(`${config.api.baseUrl}/v1/auth`, {
      credentials: "include",
    });

    if (!response.ok) {
      const loginUrl = `${config.api.baseUrl + `/v1/login?app=${window.location.href}`}`;
      window.location.href = loginUrl;
      return;
    }

    try {
      const cookieValue = getCookieValue("session_token").user;

      if (!cookieValue) {
        throw new Error("No session token found");
      }

      const userResponse = await this._userService.getUser(cookieValue.id);

      if (!userResponse.data) {
        throw userResponse.error;
      }

      const user = userResponse.data;

      this._dispatch({
        type: AuthenticationKind.LOGIN,
        payload: { user },
      });

      return user;
    } catch (error) {
      const _error = error as Error;

      this._dispatch({
        type: AuthenticationKind.ERROR,
        payload: { error: _error.message ?? "" },
      });

      return;
    }
  }

  public async logout() {
    try {
      await this._authService.logout();
      window.location.href = `${config.api.baseUrl}/v1/login?app=${window.location.href}`;

      // This is commented out because the logout we are reloading the page
      // this._dispatch({
      //   type: AuthenticationKind.LOGOUT,
      //   payload: {},
      // });
    } catch (error) {
      const _error = error as Error;

      this._dispatch({
        type: AuthenticationKind.ERROR,
        payload: { error: _error.message ?? "" },
      });
    }
  }
}
