import { config } from "../config";

export const AUTHENTICATION_LOCAL_STORAGE_USER_KEY =
  "_an-authentication-local-storage-user";

export class AuthenticationService {
  async logout(): Promise<void> {
    try {
      await fetch(`${config.api.baseUrl}/v1/logout`, {
        credentials: "include",
      });
    } catch (e) {
      console.error("error logging out", e);
    }

    return;
  }
}
