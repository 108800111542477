import client from "../api/client";

export class UsersService {
  async getUser(id: string) {
    return client.GET(`/v1/users/{userId}`, {
      params: {
        path: {
          userId: id,
        },
      },
    });
  }
}
