export function getConfig() {
  return {
    api: {
      baseUrl: import.meta.env.VITE_REALITY_OS_API_BASE_URL,
      wsUrl:
        import.meta.env.VITE_REALITY_OS_API_BASE_URL + "/v1/notifications/ws",
    },
  };
}

export const config = getConfig();
