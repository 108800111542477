import createClient from "openapi-fetch";
import type { paths } from "./v1.realityos"; // generated by openapi-typescript at build time
import { config } from "../config";

export const client = createClient<paths>({
  baseUrl: config.api.baseUrl,
  credentials: "include",
});

client.use({
  onResponse: (request) => {
    if (request.status === 401) {
      document.location.href = `${config.api.baseUrl}/v1/login?app=${document.location.href}`;
    }

    return request;
  },
});

export default client;
