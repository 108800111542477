import { ReactiveController } from "lit";
import { ContextProvider } from "@lit/context";
import { socketsMethodsContext } from "./context";
import { SocketHost } from "./types";
import { SocketClient } from "../../api/socket/client";
import { config } from "../../config";

export class SocketProvider<HostElement extends SocketHost>
  implements ReactiveController
{
  private _host: HostElement;
  // @ts-expect-error - It's currently unused, but it needs to be initialized to read the provider value
  private _methodsProvider: ContextProvider<typeof socketsMethodsContext>;
  private _socketClient: SocketClient;

  public get client(): SocketClient {
    return this._socketClient;
  }

  constructor(
    host: HostElement,
    {
      socketUrl,
    }: {
      socketUrl?: string;
    } = {},
  ) {
    this._host = host;
    this._host.addController(this);
    this._socketClient = new SocketClient({
      socketUrl: socketUrl ?? config.api.wsUrl,
      reconnect: true,
      reconnectDelay: 5000,
      reconnectDelayMax: 20000,
    });

    this._methodsProvider = new ContextProvider(host, {
      context: socketsMethodsContext,
      initialValue: this._socketClient,
    });
  }

  public hostConnected() {
    // no-op
  }
}
