import { components } from "../../api/v1.realityos";

export type AuthenticationStatus =
  | "authenticating"
  | "authenticated"
  | "unauthenticated";
export type AuthenticationError = string;
export type AuthenticationUser =
  components["schemas"]["crate.routers.api.v1.users.User"];

export type AuthenticationState = {
  status: AuthenticationStatus;
  user?: AuthenticationUser;
  error?: AuthenticationError;
};

export type AuthenticationLoginPayload = {
  user: AuthenticationUser;
};

export type AuthenticationAuthenticatingPayload = {
  status: AuthenticationStatus;
};

export type AuthenticationLogoutPayload = object;

export type AuthenticationErrorPayload = {
  error: AuthenticationError;
};

export enum AuthenticationKind {
  LOGIN,
  LOGOUT,
  AUTHENTICATING,
  ERROR,
}

export type AuthenticationAction =
  | {
      type: AuthenticationKind.LOGIN;
      payload: AuthenticationLoginPayload;
    }
  | {
      type: AuthenticationKind.LOGOUT;
      payload: AuthenticationLogoutPayload;
    }
  | {
      type: AuthenticationKind.AUTHENTICATING;
      payload: AuthenticationAuthenticatingPayload;
    }
  | {
      type: AuthenticationKind.ERROR;
      payload: AuthenticationErrorPayload;
    };

export function authenticationReducer(
  state: AuthenticationState,
  action: AuthenticationAction,
): AuthenticationState {
  switch (action.type) {
    case AuthenticationKind.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        status: "authenticated",
        error: undefined,
      };
    case AuthenticationKind.LOGOUT:
      return {
        ...state,
        user: undefined,
        status: "unauthenticated",
        error: undefined,
      };
    case AuthenticationKind.AUTHENTICATING:
      return {
        ...state,
        status: action.payload.status,
        error: undefined,
      };
    case AuthenticationKind.ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
