import { LitElement, html, isServer } from "lit";
import { Router, Routes } from "@lit-labs/router";
import { customElement } from "lit/decorators.js";
import styles from "./_app.css.js";
import { AuthenticationProvider } from "../../controllers/authentication/provider.js";
import { SocketProvider } from "../../controllers/socket/provider.js";

if (!isServer && !Object.hasOwn(globalThis, "URLPattern")) {
  await import("urlpattern-polyfill");
}

@customElement("an-app")
export class App extends LitElement {
  static override readonly styles = [styles];
  public authProvider = new AuthenticationProvider(this);
  public socketProvider = new SocketProvider(this);

  private _router = new Router(this, []);
  private _routes = new Routes(this, [
    {
      path: "/",
      enter: async () => {
        if (this.authProvider.status !== "authenticated") {
          await this.authProvider.login();
        }

        if (this.authProvider.status === "authenticated") {
          this._router.goto("/dashboard");
          window.history.replaceState({}, "", "/dashboard");
        }

        return false;
      },
    },
    {
      path: "/dashboard",
      render: () => html`<an-dashboard .router=${this._router}></an-dashboard>`,
      enter: async () => {
        await import("../dashboard/dashboard.js");
        return true;
      },
    },
    {
      path: "/dashboard/",
      enter: () => {
        this._router.goto("/dashboard");
        window.history.replaceState({}, "", "/dashboard");
        return false;
      },
    },
    {
      path: "/space/:resourceId",
      render: ({ resourceId }) =>
        html`<an-space .resourceId=${resourceId ?? ""}></an-space>`,
      enter: async () => {
        await import("../space/space.js");
        return true;
      },
    },
    {
      path: "/*",
      render: () => html`<an-not-found-page></an-not-found-page>`,
      enter: async () => {
        await import("../not-found-page/not-found-page.js");
        return true;
      },
    },
  ]);

  public override async connectedCallback() {
    super.connectedCallback();

    this.socketProvider.client.on("connect", async () => {
      console.debug(
        `User with id ${this.authProvider.user?.id} connected to socket server`,
      );
    });

    this.socketProvider.client.on("error", async (e) => {
      console.log("Error connecting to socket server", e);
    });

    this.socketProvider.client.on("disconnect", async () => {
      console.debug(
        `User with id ${this.authProvider.user?.id} disconnected from socket server`,
      );
    });

    try {
      await this.authProvider.login();

      this.requestUpdate();

      if (this.authProvider.status === "authenticated") {
        console.debug("User is authenticated", this.authProvider.user);

        if (this.authProvider.user?.id) {
          this.socketProvider.client.connect(this.authProvider.user?.id);
        }
      }
    } catch (err) {
      console.error("Error validating authentication", err);
    }
  }

  public override async disconnectedCallback() {
    super.disconnectedCallback();
    this.socketProvider.client.disconnect();
  }

  private _getTemplate() {
    if (this.authProvider.status === "authenticated") {
      return html`${this._routes.outlet()}`;
    }

    return "";
  }

  public override render() {
    return this._getTemplate();
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "an-app": App;
  }
}
